<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
    :showRating="false"
  >
    <div class="columns">
      <MedicalHistoryList
        :historyType="medicalHistoryEnum.Medical"
        :data="form.medicalHistory"
        :fullData="fullMedicalHistory"
        :residentId="residentId"
        :assessmentId="form.assessmentId"
        :readonly="readonly"
        :isLoading="isLoadingMedHist"
        :initOpen="initOpen"
        @refresh="refreshMedHist"
        :useCustomSave="true"
        @save="saveMedHist"
      />
      <MedicalHistoryList
        :historyType="medicalHistoryEnum.Surgical"
        :data="form.surgicalHistory"
        :fullData="fullSurgicalHistory"
        :residentId="residentId"
        :assessmentId="form.assessmentId"
        :readonly="readonly"
        :isLoading="isLoadingSurgHist"
        :initOpen="initOpen"
        @refresh="refreshSurgHist"
        :useCustomSave="true"
        @save="saveSurgicalHist"
      />
    </div>
    <div class="columns">
      <MedicalHistoryList
        :historyType="medicalHistoryEnum.Allergies"
        :data="form.allergiesHistory"
        :fullData="fullAllergiesHistory"
        :residentId="residentId"
        :assessmentId="form.assessmentId"
        :readonly="readonly"
        :isLoading="isLoadingAllergies"
        :initOpen="initOpen"
        @refresh="refreshAllergiesHist"
        :useCustomSave="true"
        @save="saveAllergiesHist"
      />
      <div class="column"></div>
    </div>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import MedicalHistoryList from "@/components/profile/MedicalHistoryList.vue";

import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";
import MedicalHistory from "@/enums/medicalHistoryType";

export default {
  components: { AssessmentForm, MedicalHistoryList },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.PreAdmission,
      medicalHistoryEnum: MedicalHistory.Enum,
      form: this.getClearFormObject(),

      fullMedicalHistory: null,
      fullSurgicalHistory: null,
      fullAllergiesHistory: null,
      isLoadingMedHist: false,
      isLoadingSurgHist: false,
      isLoadingAllergies: false,
      initOpen: !this.id // for new, we open history which will load init values from demographics
    };
  },

  computed: {},

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,
        medicalHistory: [],
        surgicalHistory: [],
        allergiesHistory: []
      };
    },
    // Called when assessment loaded
    mapServerData(data) {
      this.form = data; // map top level params
      this.form.assessmentDate = new Date(data.assessmentDate);
      this.setNotDirty();

      // In addition to basic stuff, we now have loaded medical history, so we open them
      this.initOpen = true;
    },

    refreshMedHist() {
      this.isLoadingMedHist = true;
      // for new assessment we load from demographics, otherwise we load for this particular assessment
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Medical };
      if (this.form.assessmentId) params.assessmentId = this.form.assessmentId; // don't send zero
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.form.medicalHistory = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Medical History"))
        .finally(() => (this.isLoadingMedHist = false));
    },
    saveMedHist(data) {
      this.fullMedicalHistory = data;
      this.form.medicalHistory = this.filterFullConditionsHistory(data.categories);
    },

    refreshSurgHist() {
      this.isLoadingSurgHist = true;
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Surgical };
      if (this.form.assessmentId) params.assessmentId = this.form.assessmentId;
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.form.surgicalHistory = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Surgical History"))
        .finally(() => (this.isLoadingSurgHist = false));
    },
    saveSurgicalHist(data) {
      this.fullSurgicalHistory = data;
      this.form.surgicalHistory = this.filterFullConditionsHistory(data.categories);
    },

    refreshAllergiesHist() {
      this.isLoadingAllergies = true;
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Allergies };
      if (this.form.assessmentId) params.assessmentId = this.form.assessmentId;
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.form.allergiesHistory = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Allergies"))
        .finally(() => (this.isLoadingAllergies = false));
    },
    saveAllergiesHist(data) {
      this.fullAllergiesHistory = data;
      this.form.allergiesHistory = this.filterFullConditionsHistory(data.categories);
    },
    filterFullConditionsHistory(categories) {
      var listData = [];
      categories.forEach(c => {
        listData = listData.concat(c.conditions.filter(h => h.hasCondition));
      });
      return listData;
    }
  }
};
</script>
